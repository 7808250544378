import { createReducer } from '@reduxjs/toolkit';
import { setEditingUser, setLoggedUser } from '../actions/data';
import initialState, { StoreContent } from "../initialState";

const dataReducer = createReducer<StoreContent>(initialState, (builder) => {
    builder.addCase(setLoggedUser, (state, action) => {
        state.user = action.payload
    });
    builder.addCase(setEditingUser, (state, action) => {
        state.editingUser = action.payload
    });
})

export default dataReducer
