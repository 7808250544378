import React, {useEffect} from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Api from '../../api/Api';
import {SnackbarOrigin, useSnackbar} from 'notistack';
import _ from 'lodash';
import {ImageDto} from "../../api/image/ImageApi";
import {FileUpload, FileUploadProps} from "../fileUpload/FileUpload";
import If from "../utils/If";
import {FileFromLinkUpload} from "../fileUpload/FileFromLinkUpload";

interface Props {
    goodItemId: string
}

export const ImageList: React.FC<Props> = (props: Props) => {
    const [images, setImages] = React.useState<ImageDto[]>([]);

    const {enqueueSnackbar} = useSnackbar();
    const snackbarAnchor = React.useMemo<SnackbarOrigin>(() => ({
        horizontal: 'center',
        vertical: 'top'
    }), []);

    const reloadAllImages = React.useCallback(() => {
        setImages([]);
        Api.image.getAllImages(props.goodItemId, responseDto => {
            setImages(responseDto.body)
        }, failure => {
            enqueueSnackbar(failure.message, {
                variant: 'error',
                anchorOrigin: snackbarAnchor
            })
        });
    }, [snackbarAnchor, enqueueSnackbar]);

    const deleteImage = React.useCallback((imageId: string) => {
        setImages([]);
        Api.image.deleteImage(imageId, responseDto => {
            reloadAllImages();
        }, failure => {
            enqueueSnackbar(failure.message, {
                variant: 'error',
                anchorOrigin: snackbarAnchor
            })
        });
    }, [snackbarAnchor, enqueueSnackbar]);

    useEffect(() => {
        reloadAllImages();
    }, [props.goodItemId]);

    const fileUploadProp: FileUploadProps = {
        key: props.goodItemId,
        onLoad: (filename, size, content) => {
            doUploadFile(filename, size, content as string)
        }
    }

    const doUploadFile = React.useCallback((filename: string, size: number, fileContent: string) => {
        Api.image.uploadImage({
            content: btoa(fileContent),
            filename: filename,
            size: size,
            goodItemId: props.goodItemId
        }, responseDto => {
            reloadAllImages();
        }, failure => {
            enqueueSnackbar(failure.message, {
                variant: 'error',
                anchorOrigin: snackbarAnchor
            })
        })
    }, [snackbarAnchor, enqueueSnackbar]);

    const doUploadFileFromLink: (fileLink: string) => void = React.useCallback((fileLink: string) => {
        Api.image.uploadImageFromLink({
            fileLink: fileLink,
            goodItemId: props.goodItemId
        }, responseDto => {
            reloadAllImages();
        }, failure => {
            enqueueSnackbar(failure.message, {
                variant: 'error',
                anchorOrigin: snackbarAnchor
            })
        })
    }, [snackbarAnchor, enqueueSnackbar]);

    return (
        <Box sx={{flexGrow: 1}}>
            <Box sx={{flexGrow: 1, overflowY: 'inherit'}}>
                <Box sx={{ mb: 1}}>
                    <FileUpload {...fileUploadProp} />
                </Box>
                <p>
                    ... OR ...
                </p>
                <Box sx={{ mb: 1 }}>
                    <FileFromLinkUpload onLoad={doUploadFileFromLink} />
                </Box>
                <Grid container={true} spacing={2}>
                    {_.map(images, (image: ImageDto) => (
                        <Grid item key={image.id} xs={12} sm={4}>
                            <Card sx={{maxWidth: 345}}>
                                <CardMedia
                                    component="img"
                                    alt={image.id}
                                    height="140"
                                    image={image.privateUrl}
                                />
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="div">
                                        {image.id}
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        Size: {image.size}
                                    </Typography>
                                    <If condition={image.dateWatermarked !== undefined && image.dateWatermarked !== null}>
                                        <Typography variant="body2" color="text.secondary">
                                            Date of watermarking: {image.dateWatermarked}
                                        </Typography>
                                    </If>
                                    <br/>
                                    <Box>
                                        <a href={image.privateUrl} target="_blank"
                                           className="MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeSmall MuiButton-textSizeSmall MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeSmall MuiButton-textSizeSmall css-1knaqv7-MuiButtonBase-root-MuiButton-root">
                                            Private URL</a>
                                    </Box>
                                    <If condition={image.publicUrl !== undefined && image.publicUrl !== null && image.publicUrl !== ''}>
                                        <Box>
                                            <a href={image.publicUrl} target="_blank"
                                               className="MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeSmall MuiButton-textSizeSmall MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeSmall MuiButton-textSizeSmall css-1knaqv7-MuiButtonBase-root-MuiButton-root">
                                                Public URL</a>
                                        </Box>
                                    </If>
                                    <If condition={image.directUrl !== undefined && image.directUrl !== null && image.directUrl !== ''}>
                                        <Box>
                                            <a href={image.directUrl} target="_blank"
                                               className="MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeSmall MuiButton-textSizeSmall MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeSmall MuiButton-textSizeSmall css-1knaqv7-MuiButtonBase-root-MuiButton-root">
                                                Direct URL</a>
                                        </Box>
                                    </If>
                                </CardContent>
                                <CardActions>
                                    <Box>
                                        <Button size="small" onClick={() => deleteImage(image.id)}>Delete</Button>
                                    </Box>
                                </CardActions>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </Box>
        </Box>
    )
}