import React from 'react';
import LoginForm from "../components/auth/login/LoginForm";
import If from "../components/utils/If";
import { useAppSelector } from "../store/store";

const AuthPage: React.FC = () => {
    const user = useAppSelector((state) => state.data.user)

    return (
        <div>
            <If condition={user.id === null || user.id === undefined || user.id === ''}>
                <LoginForm/>
            </If>
        </div>
    )
}

export default AuthPage