import React, {useEffect} from "react";
import {store, useAppSelector} from "../../store/store";
import {AppBar, CssBaseline, Toolbar, Typography,} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {Link} from "react-router-dom";
import {contextPath} from "../../api/ApiCommon";
import If from "../../components/utils/If"

const useStyles = makeStyles((theme: any) => ({
    navlinks: {
        marginLeft: "10px",
        display: "flex",
    },
    logo: {
        flexGrow: "1",
        cursor: "pointer",
    },
    link: {
        textDecoration: "none",
        color: "white",
        fontSize: "20px",
        marginLeft: "20px",
        "&:hover": {
            color: "yellow",
            borderBottom: "1px solid white",
        },
    },
}));

// source: https://javascript.works-hub.com/learn/how-to-create-a-responsive-navbar-using-material-ui-and-react-router-f9a01
function NavBar() {
    const user = useAppSelector((state) => state.data.user)
    const classes = useStyles();

    return (
        <AppBar position="static">
            <CssBaseline/>
            <Toolbar>
                <Typography variant="h4" className={classes.logo}>
                    PIC-WH
                </Typography>
                <div className={classes.navlinks}>
                    <Link to={contextPath() + "/"} className={classes.link}>
                        Home
                    </Link>
                    <Link to={contextPath() + "/catalogue"} className={classes.link}>
                        Catalogue
                    </Link>
                    <If condition={user.isSuper === true}>
                        <Link to={contextPath() + "/admin"} className={classes.link}>
                            Admin
                        </Link>
                    </If>
                </div>
            </Toolbar>
        </AppBar>
    )
        ;
}

export default NavBar;