import {contextPath, FailureLoading, handleApiError, post} from "../ApiCommon";
import {ApiResponse} from "../ApiResponse";

export type BatchFileUploadRequestDto = {
    csvFileName: string,
    csvContent: string,
    photoFileName?: string,
    photoContent?: string,
    photoFileSize?: number
}

export const BatchFileUploadApi = {
    upload: (requestDto: BatchFileUploadRequestDto,
             callback: (content: ApiResponse<any>) => void,
             onFailure: (failure: FailureLoading) => void) => {
        post<BatchFileUploadRequestDto, ApiResponse<any>>(contextPath() + "/api/v1/batch/upload", requestDto)
            .then(content => callback(content))
            .catch(error => handleApiError(error, "Unable to upload", onFailure))
    }
}