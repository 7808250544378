import { Checkbox, FormControlLabel } from "@mui/material";
import Box from "@mui/material/Box";
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { SnackbarOrigin, useSnackbar } from 'notistack';
import React from "react";
import Api from "../../../../api/Api";
import { setEditingUser } from "../../../../store/actions/data";
import { User } from "../../../../store/initialState";
import { useAppDispatch, useAppSelector } from "../../../../store/store";

interface Props {
    open: boolean,
    onClose: () => void
}

const ModifyUserForm: React.FC<Props> = (props: Props) => {
    const dispatch = useAppDispatch();
    const editingUser = useAppSelector((state) => state.data.editingUser) || {
        id: '',
        email: '',
        isSuper: false,
        abcpSearchUrl: '',
        isBatchUploadingAvailable: false,
        buBrandColumn: 0,
        buGoodItemColumn: 0,
        buPicUrlsColumn: 0
    } as User;
    const {enqueueSnackbar} = useSnackbar();
    const snackbarAnchor = {
        horizontal: 'center',
        vertical: 'top'
    } as SnackbarOrigin;

    const onIsBatchUploadingAvailableChanged = React.useCallback((event: any) => {
        const newFlag = {
            isBatchUploadingAvailable: !editingUser.isBatchUploadingAvailable
        };
        dispatch(setEditingUser({...editingUser, ...newFlag}));
    }, [props, snackbarAnchor, enqueueSnackbar]);

    const onBuBrandColumnChanged = React.useCallback((event: any) => {
        const newValue = {
            buBrandColumn: event.target.value
        };
        dispatch(setEditingUser({...editingUser, ...newValue}));
    }, [props, snackbarAnchor, enqueueSnackbar])

    const onBuGoodItemColumnChanged = React.useCallback((event: any) => {
        const newValue = {
            buGoodItemColumn: event.target.value
        };
        dispatch(setEditingUser({...editingUser, ...newValue}));
    }, [props, snackbarAnchor, enqueueSnackbar])

    const onBuPicUrlsColumnChanged = React.useCallback((event: any) => {
        const newValue = {
            buPicUrlsColumn: event.target.value
        };
        dispatch(setEditingUser({...editingUser, ...newValue}));
    }, [props, snackbarAnchor, enqueueSnackbar])

    const saveUser = React.useCallback((event: any) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        Api.userManagement.modifyUser(editingUser.id!!, {
            email: '' + data.get('email'),
            password: '' + data.get("password"),
            isBatchUploadingAvailable: Boolean(data.get('isBatchUploadingAvailable')) || false,
            buBrandColumn: Number(data.get('buBrandColumn')) || 0,
            buGoodItemColumn: Number(data.get('buGoodItemColumn')) || 0,
            buPicUrlsColumn: Number(data.get('buPicUrlsColumn')) || 0
        }, responseDto => {
            props.onClose();
        }, failure => {
            enqueueSnackbar(failure.message, {
                variant: 'error',
                anchorOrigin: snackbarAnchor
            })
        });
    }, [props, snackbarAnchor, enqueueSnackbar]);

    return (
        <Modal
            open={props.open}
            onClose={props.onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}
        >
            <Box sx={{width: 300}}>
                <Card sx={{width: 300}}>
                    <Box component="form" onSubmit={saveUser} noValidate sx={{mt: 1}}>
                        <Typography variant="h5" component="div" style={{margin: 10}}>
                            Edit user
                        </Typography>
                        <Typography variant="body2" style={{margin: 10}}>
                            E-Mail: {editingUser.email}
                            <br/>
                            User ID: {editingUser.id}
                        </Typography>
                        <CardContent>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                label="Email Address"
                                name="email"
                                autoFocus
                                value={editingUser.email}
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label="Password"
                                type="password"
                                id="password"
                            />
                            <FormControlLabel
                                required
                                control={<Checkbox 
                                    checked={editingUser.isBatchUploadingAvailable || false} 
                                    onChange={onIsBatchUploadingAvailableChanged}
                                    name="isBatchUploadingAvailable"
                                    id="isBatchUploadingAvailable"
                                    />}
                                label="CSV is Available"
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="buBrandColumn"
                                label="CSV Brand Column"
                                value={editingUser.buBrandColumn}
                                onChange={onBuBrandColumnChanged}
                                id="buBrandColumn"
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="buGoodItemColumn"
                                label="CSV Good Item Column"
                                value={editingUser.buGoodItemColumn}
                                onChange={onBuGoodItemColumnChanged}
                                id="buGoodItemColumn"
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="buPicUrlsColumn"
                                label="CSV Pic URLs Column"
                                value={editingUser.buPicUrlsColumn}
                                onChange={onBuPicUrlsColumnChanged}
                                id="buPicUrlsColumn"
                            />
                        </CardContent>
                        <CardActions>
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{mt: 3, mb: 2}}
                            >
                                Save user
                            </Button>
                        </CardActions>
                        <CardActions>
                            <Button size="medium" fullWidth onClick={props.onClose}>Cancel</Button>
                        </CardActions>
                    </Box>
                </Card>
            </Box>
        </Modal>
    );
}

export default ModifyUserForm;