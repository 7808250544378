import './Catalogue.css';
import React, {useEffect} from "react";
import SvgIcon, {SvgIconProps} from '@mui/material/SvgIcon';
import {alpha, styled} from '@mui/material/styles';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TreeView from '@mui/lab/TreeView';
import TreeItem, {treeItemClasses, TreeItemProps} from '@mui/lab/TreeItem';
import Collapse from '@mui/material/Collapse';
import {animated, useSpring} from '@react-spring/web';
import {TransitionProps} from '@mui/material/transitions';
import {SnackbarOrigin, useSnackbar} from 'notistack';
import _ from 'lodash';
import If from "../utils/If";
import {BrandDto} from "../../api/brand/BrandApi";
import {GoodItemDto} from "../../api/goodItem/GoodItemApi";
import Api from "../../api/Api";
import {ApiResponse} from "../../api/ApiResponse";
import CreateBrandForm from "./brand/create/CreateBrandForm";
import CreateGoodItemForm from "./goodItem/create/CreateGoodItemForm";
import {Grid} from "@mui/material";
import {ImageList} from "../imageList/ImageList";


function MinusSquare(props: SvgIconProps) {
    return (
        <SvgIcon fontSize="inherit" style={{width: 14, height: 14}} {...props}>
            {/* tslint:disable-next-line: max-line-length */}
            <path
                d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 11.023h-11.826q-.375 0-.669.281t-.294.682v0q0 .401.294 .682t.669.281h11.826q.375 0 .669-.281t.294-.682v0q0-.401-.294-.682t-.669-.281z"/>
        </SvgIcon>
    );
}

function PlusSquare(props: SvgIconProps) {
    return (
        <SvgIcon fontSize="inherit" style={{width: 14, height: 14}} {...props}>
            {/* tslint:disable-next-line: max-line-length */}
            <path
                d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 12.977h-4.923v4.896q0 .401-.281.682t-.682.281v0q-.375 0-.669-.281t-.294-.682v-4.896h-4.923q-.401 0-.682-.294t-.281-.669v0q0-.401.281-.682t.682-.281h4.923v-4.896q0-.401.294-.682t.669-.281v0q.401 0 .682.281t.281.682v4.896h4.923q.401 0 .682.281t.281.682v0q0 .375-.281.669t-.682.294z"/>
        </SvgIcon>
    );
}

function CloseSquare(props: SvgIconProps) {
    return (
        <SvgIcon
            className="close"
            fontSize="inherit"
            style={{width: 14, height: 14}}
            {...props}
        >
            {/* tslint:disable-next-line: max-line-length */}
            <path
                d="M17.485 17.512q-.281.281-.682.281t-.696-.268l-4.12-4.147-4.12 4.147q-.294.268-.696.268t-.682-.281-.281-.682.294-.669l4.12-4.147-4.12-4.147q-.294-.268-.294-.669t.281-.682.682-.281.696 .268l4.12 4.147 4.12-4.147q.294-.268.696-.268t.682.281 .281.669-.294.682l-4.12 4.147 4.12 4.147q.294.268 .294.669t-.281.682zM22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0z"/>
        </SvgIcon>
    );
}

function TransitionComponent(props: TransitionProps) {
    const style = useSpring({
        from: {
            opacity: 0,
            transform: 'translate3d(20px,0,0)',
        },
        to: {
            opacity: props.in ? 1 : 0,
            transform: `translate3d(${props.in ? 0 : 20}px,0,0)`,
        },
    });

    return (
        <animated.div style={style}>
            <Collapse {...props} />
        </animated.div>
    );
}

const StyledTreeItem = styled((props: TreeItemProps) => (
    <TreeItem {...props} TransitionComponent={TransitionComponent}/>
))(({theme}) => ({
    [`& .${treeItemClasses.iconContainer}`]: {
        '& .close': {
            opacity: 0.3,
        },
    },
    [`& .${treeItemClasses.group}`]: {
        marginLeft: 15,
        paddingLeft: 18,
        borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
    },
}));

type GoodItemDict = {
    [key: string]: GoodItemDto[]
}

export default function Catalogue() {
    const [brands, setBrands] = React.useState<BrandDto[]>([]);
    const [goodItems, setGoodItems] = React.useState<GoodItemDict>({});
    const [selectedBrand, setSelectedBrand] = React.useState<BrandDto | undefined>(undefined);
    const [selectedGoodItem, setSelectedGoodItem] = React.useState<GoodItemDto | undefined>(undefined);

    const [isBrandCreating, setIsBrandCreating] = React.useState<boolean>(false);
    const [isGoodItemCreating, setIsGoodItemCreating] = React.useState<boolean>(false);

    const {enqueueSnackbar} = useSnackbar();
    const snackbarAnchor = React.useMemo<SnackbarOrigin>(() => ({
        horizontal: 'center',
        vertical: 'top'
    }), []);

    const startCreatingBrand = React.useCallback(() => {
        setIsBrandCreating(true);
    }, [])

    const stopCreatingBrand = React.useCallback((brandId: string | undefined) => {
        setIsBrandCreating(false);
        if (brandId) {
            reloadBrands(() =>
                reloadGoodItemsByBrandId(brandId));
        }
    }, []);

    const startCreatingGoodItem = React.useCallback(() => {
        setIsGoodItemCreating(true);
    }, [])

    const stopCreatingGoodItem = React.useCallback((goodItemId: string | undefined) => {
        setIsGoodItemCreating(false);
        if (goodItemId) {
            const brandId = selectedBrand!!.id;
            reloadBrands(() =>
                selectBrand(brandId, () =>
                    reloadGoodItemsByBrandId(brandId, () =>
                        selectGoodItem(goodItemId))));
        }
    }, [selectedBrand]);

    const deleteBrand = React.useCallback(() => {
        if (selectedBrand !== null) {
            const brandId = selectedBrand!!.id;
            Api.brand.deleteBrand(brandId, () => {
                setSelectedBrand(undefined);
                setSelectedGoodItem(undefined);
                reloadBrands();
            }, failure => {
                enqueueSnackbar(failure.message, {
                    variant: 'error',
                    anchorOrigin: snackbarAnchor
                })
            });
        }
    }, [selectedBrand]);

    const deleteGoodItem = React.useCallback(() => {
        if (selectedGoodItem !== null) {
            const goodItemId = selectedGoodItem!!.id;
            Api.goodItem.deleteGoodItem(goodItemId, () => {
                setSelectedGoodItem(undefined);
                reloadBrands(() =>
                    reloadGoodItemsByBrandId(selectedBrand!!.id));
            }, failure => {
                enqueueSnackbar(failure.message, {
                    variant: 'error',
                    anchorOrigin: snackbarAnchor
                })
            });
        }
    }, [selectedGoodItem]);

    const reloadBrands = React.useCallback((callback?: () => void) => {
        Api.brand.getAllBrands((responseDto: ApiResponse<BrandDto[]>) => {
            setBrands(responseDto.body);
            if (callback) {
                callback();
            }
        }, failure => {
            enqueueSnackbar(failure.message, {
                variant: 'error',
                anchorOrigin: snackbarAnchor
            })
        });
    }, [snackbarAnchor, enqueueSnackbar]);

    const reloadGoodItems = React.useCallback(() => {
        _.forEach(brands, (brand: BrandDto) => {
            reloadGoodItemsByBrandId(brand.id)
        });
    }, [brands]);

    const reloadGoodItemsByBrandId = React.useCallback((brandId: string, callback?: () => void) => {
        Api.goodItem.getAllGoodItems(brandId, (responseDto: ApiResponse<GoodItemDto[]>) => {
            setGoodItems({
                ...goodItems,
                [brandId]: responseDto.body
            });
            if (callback) {
                callback();
            }
        }, failure => {
            enqueueSnackbar(failure.message, {
                variant: 'error',
                anchorOrigin: snackbarAnchor
            })
        });
    }, [goodItems, snackbarAnchor, enqueueSnackbar]);

    const onNodeSelected = React.useCallback((event: React.SyntheticEvent, nodeIds: Array<string> | string) => {
        const nodeId = "" + nodeIds;
        if (nodeId.startsWith("brand-")) {
            const brandId = nodeId.substring("brand-".length);
            selectBrand(brandId);
            reloadGoodItemsByBrandId(brandId);
        }
        if (nodeId.startsWith("goodItem-")) {
            const selectedGoodItemId = nodeId.substring("goodItem-".length);
            Object.keys(goodItems).forEach(brandId => {
                _.forEach(goodItems[brandId], (goodItem: GoodItemDto) => {
                    if (goodItem.id === selectedGoodItemId) {
                        const selectedBrandByGoodItem = _.find(brands, {id: goodItem.brandId});
                        selectBrand(selectedBrandByGoodItem.id);
                    }
                })
            });
            selectGoodItem(selectedGoodItemId);
        }
    }, [goodItems, brands]);

    const selectBrand = React.useCallback((brandId: string, callback?: () => void) => {
        setSelectedGoodItem(undefined);
        Api.brand.getBrand(brandId, responseDto => {
            setSelectedBrand(responseDto.body)
            if (callback) {
                callback();
            }
        }, failure => {
            enqueueSnackbar(failure.message, {
                variant: 'error',
                anchorOrigin: snackbarAnchor
            })
        })
    }, [snackbarAnchor, enqueueSnackbar]);

    const selectGoodItem = React.useCallback((goodItemId: string) => {
        Api.goodItem.getGoodItem(goodItemId, responseDto => {
            setSelectedGoodItem(responseDto.body)
        }, failure => {
            enqueueSnackbar(failure.message, {
                variant: 'error',
                anchorOrigin: snackbarAnchor
            })
        })
    }, [snackbarAnchor, enqueueSnackbar]);

    const path = React.useCallback(() => {
        if (selectedBrand && selectedGoodItem) {
            return `${selectedBrand.name} / ${selectedGoodItem.vendorCode}`;
        } else if (selectedBrand) {
            return `${selectedBrand.name}`;
        } else {
            return '';
        }
    }, [selectedBrand, selectedGoodItem]);

    useEffect(() => {
        reloadBrands();
    }, []);

    useEffect(() => {
        reloadGoodItems();
    }, [brands]);

    const createBrandNodeId = React.useCallback((brandId: string) => {
        return 'brand-' + brandId;
    }, []);

    const createGoodItemNodeId = React.useCallback((goodItemId: string) => {
        return 'goodItem-' + goodItemId;
    }, []);

    const getSelectedNodeId = React.useCallback(() => {
        if (selectedGoodItem) {
            return createGoodItemNodeId(selectedGoodItem.id);
        } else if (selectedBrand) {
            return createBrandNodeId(selectedBrand.id);
        } else {
            return '';
        }
    }, [selectedBrand, selectedGoodItem]);

    return (
        <div>
            <CreateBrandForm open={isBrandCreating} onClose={stopCreatingBrand}/>
            <CreateGoodItemForm open={isGoodItemCreating} onClose={stopCreatingGoodItem} brand={selectedBrand}/>
            <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                    <Box sx={{height: 470, flexGrow: 1, maxWidth: 400, overflowY: 'auto'}}>
                        <Box sx={{mb: 1}}>
                            <Button onClick={startCreatingBrand}>
                                New Brand
                            </Button>
                            <If condition={selectedBrand !== undefined} className={'inline-button-wrapper'}>
                                <Button onClick={startCreatingGoodItem}>
                                    New Good Item
                                </Button>
                            </If>
                        </Box>
                        <TreeView
                            aria-label="customized"
                            defaultCollapseIcon={<MinusSquare/>}
                            defaultExpandIcon={<PlusSquare/>}
                            defaultEndIcon={<CloseSquare/>}
                            selected={getSelectedNodeId()}
                            onNodeSelect={onNodeSelected}
                            sx={{height: 364, flexGrow: 1, maxWidth: 400, overflowY: 'auto'}}
                        >
                            {_.map(brands, (brandItem: BrandDto) => (
                                <StyledTreeItem key={brandItem.id}
                                                nodeId={createBrandNodeId(brandItem.id)}
                                                label={`${brandItem.name} (${brandItem.goodItemsCount})`}>
                                    {_.map(goodItems[brandItem.id], (goodItem: GoodItemDto) => (
                                        <StyledTreeItem key={goodItem.id}
                                                        nodeId={createGoodItemNodeId(goodItem.id)}
                                                        label={goodItem.vendorCode}/>
                                    ))}
                                </StyledTreeItem>
                            ))}
                        </TreeView>
                    </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                    <div>
                        <Grid container spacing={1}>
                            <Grid item xs={3}>
                                <If condition={selectedBrand !== undefined}>
                                    <Button variant="outlined" color="error" onClick={deleteBrand}>
                                        Delete Brand
                                    </Button>
                                </If>
                            </Grid>
                            <Grid item xs={4}>
                                <If condition={selectedGoodItem !== undefined}>
                                    <Button variant="outlined" color="error" onClick={deleteGoodItem}>
                                        Delete Good Item
                                    </Button>
                                </If>
                            </Grid>
                        </Grid>
                        <div>
                            <h3>
                                {path()}
                            </h3>
                        </div>
                        <If condition={selectedGoodItem !== undefined}>
                            <ImageList goodItemId={selectedGoodItem !== undefined ? selectedGoodItem.id : ''}/>
                        </If>
                    </div>
                </Grid>
            </Grid>
        </div>
    );
}