import {contextPath, FailureLoading, handleApiError, post} from "../ApiCommon";
import {ApiResponse} from "../ApiResponse";

export type CreateBrandRequestDto = {
    name: string;
}

export type BrandDto = {
    id: string,
    name: string,
    abcpName: string,
    goodItemsCount: number
}

export type UpdateBrandRequestDto = {
    name: string
}

export const BrandApi = {
    createBrand: (createBrandRequestDto: CreateBrandRequestDto,
                  callback: (content: ApiResponse<BrandDto>) => void,
                  onFailure: (failure: FailureLoading) => void) => {
        post<CreateBrandRequestDto, ApiResponse<BrandDto>>(contextPath() + '/api/v1/brands/create-brand', createBrandRequestDto)
            .then(content => callback(content))
            .catch(error => handleApiError(error, "Unable to create brand", onFailure))
    },

    updateBrand: (brandId: string,
                  updateBrandRequestDto: UpdateBrandRequestDto,
                  callback: (content: ApiResponse<BrandDto>) => void,
                  onFailure: (failure: FailureLoading) => void) => {
        post<UpdateBrandRequestDto, ApiResponse<BrandDto>>(contextPath() + 'api/v1/brands/update-brand/' + brandId, updateBrandRequestDto)
            .then(content => callback(content))
            .catch(error => handleApiError(error, "Unable to update brand", onFailure))
    },

    getAllBrands: (callback: (content: ApiResponse<BrandDto[]>) => void,
                   onFailure: (failure: FailureLoading) => void) => {
        post<null, ApiResponse<BrandDto[]>>(contextPath() + '/api/v1/brands/get-all-brands', null)
            .then(content => callback(content))
            .catch(error => handleApiError(error, "Unable to get all brands", onFailure))
    },

    getBrand: (brandId: string,
               callback: (content: ApiResponse<BrandDto>) => void,
               onFailure: (failure: FailureLoading) => void) => {
        post<null, ApiResponse<BrandDto>>(contextPath() + '/api/v1/brands/get-brand/' + brandId, null)
            .then(content => callback(content))
            .catch(error => handleApiError(error, "Unable to get brand", onFailure))
    },

    deleteBrand: (brandId: string,
                  callback: (content: ApiResponse<any>) => void,
                  onFailure: (failure: FailureLoading) => void) => {
        post<null, ApiResponse<any>>(contextPath() + '/api/v1/brands/delete-brand/' + brandId, null)
            .then(content => callback(content))
            .catch(error => handleApiError(error, "Unable to delete brand", onFailure))
    }
}