import React from 'react'
import {Container, Grid} from "@mui/material";

const AboutPage: React.FC = () => {

    return (
        <Container>
            <Grid container={true} spacing={2}>
                <Grid item xs={12} md={12} sm={12}>
                    <h1>
                        Подключиться
                    </h1>
                    <p>
                        Для подключения напишите заявку на почту <a
                        href="mailto:register@pic-wh.ru">register@pic-wh.ru</a>
                    </p>
                </Grid>
                <Grid item xs={12} md={12} sm={12}>
                    <h1>
                        О проекте
                    </h1>
                    <p>
                        Этот проект является дополнением к <a href="https://www.abcp.ru">платформе автозапчастей
                        ABCP</a>.
                    </p>
                    <p>
                        При создании своего магазина в платформе ABCP вы получаете уникальную возможность создать его "в
                        пару кликов".
                        Все картинки товаров вам предоставляет платформа. Однако если вы захотите
                        использовать <b>свои <i>уникальные</i></b> фотографии автозапчастей, у вас этого сделать не
                        получится.
                    </p>
                    <p>
                        Данный сайт <a href="https://pic-wh.ru">Pic-WH</a> даёт вам уникальную возможность использовать
                        свои <b>собственные</b> фотографии, на которые наложен ваш <b>собственный логотип</b> в виде
                        водяного знака!
                    </p>
                    <p>
                        <ul>Возможности:
                            <li>- каталогизатор фотографий: личная коллекция с группировкой по артикулу и бренду</li>
                            <li>- автоматическое наложение водяного знака (публикация в ABCP идет только при наличии
                                водяного знака, если он загружен, но есть возможность скачивать оригиналы)</li>
                            <li>- фотографии размещаются в облаках (S3) - ограничения места нет</li>
                            <li>- скорость оптимизирована</li>
                            <li>- поддержка на доработки</li>
                        </ul>
                    </p>
                </Grid>
                <Grid item xs={12} md={12} sm={12}>
                    <h1>Добавление водяного знака выглядит так</h1>
                    <img src="/img/about/watermark.png" width="800" height="600"/>
                </Grid>
                <Grid item xs={12} md={12} sm={12}>
                    <h1>Рабочее место выглядит так</h1>
                    <img src="/img/about/how-to-brand.png" width="800" height="600"/>
                </Grid>
            </Grid>
        </Container>
    )
}

export default AboutPage