import {contextPath, FailureLoading, handleApiError, post} from '../ApiCommon'
import {ApiResponse} from "../ApiResponse";

export const AdminApi = {
    migrate: (callback: (content: ApiResponse<string>) => void,
              onFailure: (failure: FailureLoading) => void) => {
        post<null, ApiResponse<string>>(contextPath() + '/api/v1/admin/migrate-wm-images', null)
            .then(content => callback(content))
            .catch(error => handleApiError(error, "Unable to perform migration", onFailure))
    },
    addExtensionToImagesPublicPath: (callback: (content: ApiResponse<string>) => void,
                                     onFailure: (failure: FailureLoading) => void) => {
        post<null, ApiResponse<string>>(contextPath() + '/api/v1/admin/images/add-extension', null)
            .then(content => callback(content))
            .catch(error => handleApiError(error, "Unable to start adding extension to images public path", onFailure))
    },
    updateAbcpBrandNames: (callback: (content: ApiResponse<string>) => void,
                                     onFailure: (failure: FailureLoading) => void) => {
        post<null, ApiResponse<string>>(contextPath() + '/api/v1/admin/update-brand-names', null)
            .then(content => callback(content))
            .catch(error => handleApiError(error, "Unable to start updating ABCP brand names", onFailure))
    }
}