import React from 'react'
import {SnackbarOrigin, useSnackbar} from 'notistack';
import {Container, Grid} from "@mui/material";
import UserManagementTable from "../components/user/management/UserManagementTable";
import Button from "@mui/material/Button";
import Api from '../api/Api'

const AdminPage: React.FC = () => {

    const {enqueueSnackbar} = useSnackbar();
    const snackbarAnchor = React.useMemo<SnackbarOrigin>(() => ({
        horizontal: 'center',
        vertical: 'top'
    }), []);

    const startMigration = React.useCallback(() => {
        Api.admin.migrate(content => {
            enqueueSnackbar("Started to migrate: " + content.body, {
                variant: 'success',
                anchorOrigin: snackbarAnchor
            });
        }, failure => {
            enqueueSnackbar("Failed to migrate" + failure.message, {
                variant: 'error',
                anchorOrigin: snackbarAnchor
            });
        });
    }, []);

    const startAddingExtensionToImagesPublicPath = React.useCallback(() => {
        Api.admin.addExtensionToImagesPublicPath(content => {
            enqueueSnackbar("Started adding extension: " + content.body, {
                variant: 'success',
                anchorOrigin: snackbarAnchor
            });
        }, failure => {
            enqueueSnackbar("Failed to start adding extension" + failure.message, {
                variant: 'error',
                anchorOrigin: snackbarAnchor
            });
        });
    }, []);

    const startUpdatingAbcpBrandNames = React.useCallback(() => {
        Api.admin.updateAbcpBrandNames(content => {
            enqueueSnackbar("Started updating brand names: " + content.body, {
                variant: 'success',
                anchorOrigin: snackbarAnchor
            });
        }, failure => {
            enqueueSnackbar("Failed to start updating brand names" + failure.message, {
                variant: 'error',
                anchorOrigin: snackbarAnchor
            });
        });
    }, []);

    return (
        <Container>
            <Grid container={true} spacing={2}>
                <Grid item xs={12} md={12} sm={12}>
                    <Button onClick={startMigration}>Migrate WM images</Button>
                </Grid>
                <Grid item xs={12} md={12} sm={12}>
                    <Button onClick={startAddingExtensionToImagesPublicPath}>Add extension for images</Button>
                </Grid>
                <Grid item xs={12} md={12} sm={12}>
                    <Button onClick={startUpdatingAbcpBrandNames}>Update ABCP Brand names</Button>
                </Grid>
                <Grid item xs={12} md={12} sm={12}>
                    <UserManagementTable/>
                </Grid>
            </Grid>
        </Container>
    )
}

export default AdminPage
