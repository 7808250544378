import React from "react";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Api from "../../../../api/Api";
import {SnackbarOrigin, useSnackbar} from 'notistack';
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

interface Props {
    open: boolean,
    onClose: () => void
}

const CreateUserForm: React.FC<Props> = (props: Props) => {
    const {enqueueSnackbar} = useSnackbar();
    const snackbarAnchor = React.useMemo<SnackbarOrigin>(() => ({
        horizontal: 'center',
        vertical: 'top'
    }), []);

    const saveNewUser = React.useCallback((event: any) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        Api.userManagement.createUser({
            email: '' + data.get('email'),
            password: '' + data.get("password")
        }, response => {
            props.onClose();
        }, failure => {
            enqueueSnackbar(failure.message, {
                variant: 'error',
                anchorOrigin: snackbarAnchor
            })
        });
    }, [props, snackbarAnchor, enqueueSnackbar]);

    return (
        <Modal
            open={props.open}
            onClose={props.onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}
        >
            <Box sx={{width: 300}}>
                <Card sx={{width: 300}}>
                    <Box component="form" onSubmit={saveNewUser} noValidate sx={{mt: 1}}>
                        <Typography variant="h5" component="div" style={{margin: 10}}>
                            Create new user
                        </Typography>
                        <CardContent>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                label="Email Address"
                                name="email"
                                autoFocus
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label="Password"
                                type="password"
                                id="password"
                            />
                        </CardContent>
                        <CardActions>
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{mt: 3, mb: 2}}
                            >
                                Create user
                            </Button>
                        </CardActions>
                        <CardActions>
                            <Button size="medium" fullWidth onClick={props.onClose}>Cancel</Button>
                        </CardActions>
                    </Box>
                </Card>
            </Box>
        </Modal>
    );
}

export default CreateUserForm;