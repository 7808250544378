import {contextPath, FailureLoading, handleApiError, post} from "../ApiCommon";
import {ApiResponse} from "../ApiResponse";

export type SetWatermarkRequestDto = {
    content: string,
    filename: string,
    size: number
}

export const WatermarkApi = {
    setWatermark: (setWatermarkRequestDto: SetWatermarkRequestDto,
                  callback: (responseDto: ApiResponse<null>) => void,
                  onFailure: (failure: FailureLoading) => void) => {
        post<SetWatermarkRequestDto, ApiResponse<null>>(contextPath() + '/api/v1/watermarks/set-watermark', setWatermarkRequestDto)
            .then(content => callback(content))
            .catch(error => handleApiError(error, "Unable to upload watermark", onFailure))
    }
};