import { Box, Button, Container, Grid } from "@mui/material";
import { SnackbarOrigin, useSnackbar } from 'notistack';
import * as React from "react";
import Api from '../../api/Api';
import { FileUpload, FileUploadProps } from "../fileUpload/FileUpload";
import Base64 from "../utils/Base64";
import './BatchUpload.css';

interface Props {
}

interface FileDescription {
    filename: string,
    size: number,
    content: string | any
}

const BatchUpload: React.FC<Props> = (props: Props) => {
    const {enqueueSnackbar} = useSnackbar();
    const snackbarAnchor = React.useMemo<SnackbarOrigin>(() => ({
        horizontal: 'center',
        vertical: 'top'
    }), []);

    const [csvFile, setCsvFile] = React.useState<FileDescription>();
    const [photoFile, setPhotoFile] = React.useState<FileDescription>();

    const sayFileAccepted = () => {
        enqueueSnackbar('Файл принят', {
            variant: "info",
            anchorOrigin: snackbarAnchor,
            autoHideDuration: 2000
        });
    }

    const csvUploadProp: FileUploadProps = {
        onLoad: (filename, size, content) => {
            setCsvFile({
                filename: filename,
                size: size,
                content: content as string
            });
            sayFileAccepted();
        },
        accept: {
            'text/csv': ['.csv']
        },
        isText: true
    }

    const photoUploadProp: FileUploadProps = {
        onLoad: (filename, size, content) => {
            setPhotoFile({
                filename: filename,
                size: size,
                content: content as string
            });
            sayFileAccepted();
        }
    }

    const onUploadButtonClick = () => {
        if (csvFile) {
            alert('Не закрывайте вкладку браузера, пока не закончится процесс')
            Api.batch.upload({
                csvFileName: csvFile.filename,
                csvContent: Base64.encode(csvFile.content),
                photoFileName: photoFile?.filename ?? undefined,
                photoContent: btoa(photoFile?.content) ?? undefined,
                photoFileSize: photoFile?.size ?? 0
            }, content => {
                alert('Файл загружен')
            }, failure => {
                enqueueSnackbar(failure.message, {
                    variant: 'error',
                    anchorOrigin: snackbarAnchor
                });
            });
        } else {
            alert('Загрузите CSV файл и (опционально) файл с общей картинкой');
        }
    }

    return (
        <Container>
            <Grid container={true} spacing={2}>
                <Grid item xs={2}/>
                <Grid item xs={4}>
                    Загрузить файл CSV:<br/>
                    <Box>
                        <FileUpload key='batch-csv' {...csvUploadProp} />
                    </Box>
                </Grid>
                <Grid item xs={4}>
                    Загрузить файл с картинкой:<br/>
                    <Box>
                        <FileUpload key='batch-photo' {...photoUploadProp} />
                    </Box>
                </Grid>
                <Grid item xs={2}>
                    <Button onClick={onUploadButtonClick}>Загрузить</Button>
                </Grid>
            </Grid>
        </Container>
    );
}

export default BatchUpload;