import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { SnackbarOrigin, useSnackbar } from 'notistack';
import * as React from "react";
import { useEffect } from "react";
import api from "../../../api/Api";
import { setLoggedUser } from "../../../store/actions/data";
import { User } from "../../../store/initialState";
import { useAppDispatch } from "../../../store/store";
import './LoginForm.css';

// source: https://frontendshape.com/post/react-mui-5-login-page-example
const LoginForm = () => {
    const dispatch = useAppDispatch()
    const {enqueueSnackbar} = useSnackbar();
    const snackbarAnchor = {
        horizontal: 'center',
        vertical: 'top'
    } as SnackbarOrigin;

    const handleSubmit = React.useCallback((event: any) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        api.auth.auth({
            email: '' + data.get('email'),
            password: '' + data.get("password")
        }, response => {
            dispatch(setLoggedUser({
                id: response.body.userId,
                email: response.body.email,
                isSuper: response.body.isSuper,
                abcpSearchUrl: response.body.abcpSearchUrl
            }))
        }, failure => {
            enqueueSnackbar(failure.message, {
                variant: 'error',
                anchorOrigin: snackbarAnchor
            })
        });
    }, [snackbarAnchor, enqueueSnackbar]);

    useEffect(() => {
        dispatch(setLoggedUser({} as User))
    }, [])

    return (
        <Container component="main" maxWidth="xs">
            <Box
                sx={{
                    marginTop: 8,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}
            >
                <Typography component="h1" variant="h5">
                    Войти
                </Typography>
                <Box component="form" onSubmit={handleSubmit} noValidate sx={{mt: 1}}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                        autoFocus
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{mt: 3, mb: 2}}
                    >
                        Войти
                    </Button>
                </Box>
            </Box>
        </Container>
    )
}

export default LoginForm;