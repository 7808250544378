import { Box, Container, Grid, ImageList, ImageListItem } from "@mui/material";
import _ from 'lodash';
import { SnackbarOrigin, useSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import Api from '../api/Api';
import { contextPath } from "../api/ApiCommon";
import BatchUpload from '../components/batch/BatchUpload';
import { FileUpload, FileUploadProps } from "../components/fileUpload/FileUpload";
import Profile from '../components/user/home/Profile';
import If from "../components/utils/If";
import { useAppSelector } from "../store/store";

const HomePage: React.FC = () => {
    const user = useAppSelector((state) => state.data.user)
    const [images, setImages] = React.useState<string[]>([]);
    const [reload, setReload] = React.useState<number>(0);

    const {enqueueSnackbar} = useSnackbar();
    const snackbarAnchor = React.useMemo<SnackbarOrigin>(() => ({
        horizontal: 'center',
        vertical: 'top'
    }), []);

    const reloadWatermark = React.useCallback(() => {
        setReload(reload + 1);
    }, [reload]);

    const fileUploadProp: FileUploadProps = {
        onLoad: (filename, size, content) => {
            doUploadFile(filename, size, content as string)
        }
    }

    const doUploadFile = React.useCallback((filename: string, size: number, fileContent: string) => {
        Api.watermark.setWatermark({
            content: btoa(fileContent),
            filename: filename,
            size: size
        }, responseDto => {
            reloadWatermark();
        }, failure => {
            enqueueSnackbar(failure.message, {
                variant: 'error',
                anchorOrigin: snackbarAnchor
            })
        })
    }, [snackbarAnchor, enqueueSnackbar]);

    useEffect(() => {
        setImages([contextPath() + '/resources/watermarks/' + user.id + '?d=' + (new Date())]);
    }, [reload]);

    return (
        <Container>
            <Grid container={true} spacing={2}>
                <Grid item xs={2}/>
                <Grid item xs={8}>
                    <Profile></Profile>
                </Grid>
                <Grid item xs={2}/>
            </Grid>
            <If condition={user.id !== undefined}>
                <Grid container={true} spacing={2}>
                    <Grid item xs={2}/>
                    <Grid item xs={8}>
                        <BatchUpload></BatchUpload>
                    </Grid>
                </Grid>
                <Grid container={true} spacing={2}>
                    <Grid item xs={2}/>
                    <Grid item xs={8}>
                        Watermark:<br/>
                        <Box>
                            <FileUpload key='watermark' {...fileUploadProp} />
                        </Box>
                        {_.map(images, (image: string) => (
                            <ImageList key={image} sx={{height: 450}} cols={1} rowHeight={300}>
                                <ImageListItem>
                                    <img
                                        src={image}
                                        loading="lazy"
                                    />
                                </ImageListItem>
                            </ImageList>
                        ))}
                    </Grid>
                    <Grid item xs={2}/>
                </Grid>
            </If>
        </Container>
    )
}

export default HomePage;
