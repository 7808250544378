import React, {useCallback} from 'react';
import {useDropzone} from 'react-dropzone';
import styled from 'styled-components';

const getColor = (props: any) => {
    if (props.isDragAccept) {
        return '#00e676';
    }
    if (props.isDragReject) {
        return '#ff1744';
    }
    if (props.isFocused) {
        return '#2196f3';
    }
    return '#eeeeee';
}

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: ${props => getColor(props)};
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border .24s ease-in-out;
`;

export interface FileUploadProps {
    key?: string,
    onLoad: (filename: string, size: number, content: string | ArrayBuffer | null) => void,
    accept?: any,
    isText?: boolean
}

export const FileUpload: React.FC<FileUploadProps> = (props: FileUploadProps) => {
    const onDrop = useCallback((acceptedFiles: File[]) => {
        acceptedFiles.forEach((file) => {
            const reader = new FileReader()
            reader.onabort = () => console.log('file reading was aborted')
            reader.onerror = () => console.log('file reading has failed')
            reader.onload = () => {
                // Do whatever you want with the file contents
                const content = reader.result
                // console.log(binaryStr)
                props.onLoad(file.name, file.size, content);
            }
            if (props.isText === true) {
                reader.readAsText(file)
            } else {
                reader.readAsBinaryString(file)
            }
        })

    }, [props.onLoad]);

    const {
        getRootProps,
        getInputProps,
        isFocused,
        isDragAccept,
        isDragReject
    } = useDropzone({
        onDrop, accept: props.accept || {
            'image/*': ['.png', '.jpg']
        }
    });

    return (
        <div className="container">
            <Container {...getRootProps({isFocused, isDragAccept, isDragReject})}>
                <input {...getInputProps()} />
                <p>Drag 'n' drop some files here, or click to select files</p>
            </Container>
        </div>
    )
}