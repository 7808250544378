import React from 'react';
import { HashRouter, Route, Routes } from 'react-router-dom';
import NavBar from "./components/navbar/NavBar";
import If from "./components/utils/If";
import { GlobalHistory } from "./history/History";
import AboutPage from "./pages/AboutPage";
import AdminPage from "./pages/AdminPage";
import AuthPage from "./pages/AuthPage";
import CataloguePage from "./pages/CataloguePage";
import HomePage from './pages/HomePage';
import NotFoundPage from './pages/NotFoundPage';
import { ROUTES } from './resources/routes-constants';
import './RootComponent.css';
import { useAppSelector } from "./store/store";

const RootComponent: React.FC = () => {
    const user = useAppSelector((state) => state.data.user)

    return (
        <HashRouter>
            <GlobalHistory/>
            <If condition={user.id !== undefined && user.id !== null && user.id !== ''}>
                <NavBar/>
            </If>
            <Routes>
                <Route path="*" element={<div className="main-content"><NotFoundPage/></div>}/>
                <Route path={ROUTES.HOMEPAGE_ROUTE} element={
                    <div>
                        <If condition={user.id !== undefined && user.id !== null && user.id !== ''}>
                            <div className="main-content">
                                <HomePage/>
                            </div>
                        </If>
                        <If condition={user.id === undefined || user.id === null || user.id === ''}>
                            <div className="main-content">
                                <AuthPage/>
                                <AboutPage/>
                            </div>
                        </If>
                    </div>
                }/>
                <Route path={ROUTES.LOCALHOST_ROUTE} element={
                    <div>
                        <If condition={user.id !== undefined && user.id !== null && user.id !== ''}>
                            <div className="main-content">
                                <HomePage/>
                            </div>
                        </If>
                        <If condition={user.id === undefined || user.id === null || user.id === ''}>
                            <div className="main-content">
                                <AuthPage/>
                                <AboutPage/>
                            </div>
                        </If>
                    </div>
                }/>
                <Route path={ROUTES.ADMIN_ROUTE} element={<div className="main-content"><AdminPage/></div>}/>
                <Route path={ROUTES.CATALOGUE_ROUTE} element={<div className="main-content"><CataloguePage/></div>}/>
            </Routes>
        </HashRouter>
    )
}

export default RootComponent
