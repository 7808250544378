import {contextPath, FailureLoading, handleApiError, post} from "../ApiCommon";
import {ApiResponse} from "../ApiResponse";

export type AuthRequestDto = {
    email: string,
    password: string
}

export type AuthResponseDto = {
    userId: string,
    email: string,
    isSuper: boolean,
    abcpSearchUrl: string
}

export const AuthApi = {
    auth: (authRequestDto: AuthRequestDto,
           callback: (content: ApiResponse<AuthResponseDto>) => void,
           onFailure: (failure: FailureLoading) => void) => {
        post<AuthRequestDto, ApiResponse<AuthResponseDto>>(contextPath() + '/api/v1/auth', authRequestDto)
            .then(content => callback(content))
            .catch(error => handleApiError(error, "Unable to authenticate", onFailure))
    }
}