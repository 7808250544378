import {contextPath, FailureLoading, handleApiError, post} from "../ApiCommon";
import {ApiResponse} from "../ApiResponse";

export type UploadImageRequestDto = {
    goodItemId: string,
    content: string,
    filename: string,
    size: number
}

export type UploadImageFromLinkRequestDto = {
    goodItemId: string,
    fileLink: string
}

export type ImageDto = {
    id: string,
    goodItemId: string,
    size: number,
    dateWatermarked: string | undefined,
    privateUrl: string,
    publicUrl: string,
    directUrl: string
}

export type ImageContentDto = {
    id: string,
    goodItemId: string,
    size: number,
    content: string,
}

export const ImageApi = {
    uploadImage: (uploadImageRequestDto: UploadImageRequestDto,
                  callback: (responseDto: ApiResponse<ImageDto>) => void,
                  onFailure: (failure: FailureLoading) => void) => {
        post<UploadImageRequestDto, ApiResponse<ImageDto>>(contextPath() + '/api/v1/images/upload-image', uploadImageRequestDto)
            .then(content => callback(content))
            .catch(error => handleApiError(error, "Unable to upload image", onFailure))
    },

    uploadImageFromLink: (uploadImageFromLinkRequestDto: UploadImageFromLinkRequestDto,
                          callback: (responseDto: ApiResponse<ImageDto>) => void,
                          onFailure: (failure: FailureLoading) => void) => {
       post<UploadImageFromLinkRequestDto, ApiResponse<ImageDto>>(contextPath() + '/api/v1/images/upload-image-link', uploadImageFromLinkRequestDto)
           .then(content => callback(content))
           .catch(error => handleApiError(error, "Unable to upload image", onFailure))
    },

    getAllImages: (goodItemId: string,
                   callback: (responseDto: ApiResponse<ImageDto[]>) => void,
                   onFailure: (failure: FailureLoading) => void) => {
        post<null, ApiResponse<ImageDto[]>>(contextPath() + '/api/v1/images/get-all-images/' + goodItemId, null)
            .then(content => callback(content))
            .catch(error => handleApiError(error, "Unable to get all images", onFailure))
    },

    getImageContent: (imageId: string,
                      callback: (responseDto: ApiResponse<ImageContentDto>) => void,
                      onFailure: (failure: FailureLoading) => void) => {
        post<string, ApiResponse<ImageContentDto>>(contextPath() + '/api/v1/iages/get-content', imageId)
            .then(content => callback(content))
            .catch(error => handleApiError(error, "Unable to get image content", onFailure))
    },

    deleteImage: (imageId: string,
                  callback: (responseDto: ApiResponse<any>) => void,
                  onFailure: (failure: FailureLoading) => void) => {
        post<null, ApiResponse<any>>(contextPath() + '/api/v1/images/delete-image/' + imageId, null)
            .then(content => callback(content))
            .catch(error => handleApiError(error, "Unable to delete image", onFailure))
    },

    buildRawImageContentUrl: (imageId: string) => contextPath() + "/resources/images/" + imageId
}