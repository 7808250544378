import {contextPath, FailureLoading, handleApiError, post} from "../ApiCommon";
import {ApiResponse} from "../ApiResponse";

export type CreateGoodItemRequestDto = {
    brandId: string,
    vendorCode: string;
}

export type GoodItemDto = {
    id: string;
    brandId: string,
    vendorCode: string;
    abcpVendorCode: string;
}

export type UpdateBrandRequestDto = {
    name: string
}

export const GoodItemApi = {
    createGoodItem: (createGoodItemRequestDto: CreateGoodItemRequestDto,
                     callback: (content: ApiResponse<GoodItemDto>) => void,
                     onFailure: (failure: FailureLoading) => void) => {
        post<CreateGoodItemRequestDto, ApiResponse<GoodItemDto>>(contextPath() + '/api/v1/good-items/create-good-item', createGoodItemRequestDto)
            .then(content => callback(content))
            .catch(error => handleApiError(error, "Unable to create good item", onFailure))
    },

    updateGoodItem: (brandId: string,
                     updateBrandRequestDto: UpdateBrandRequestDto,
                     callback: (content: ApiResponse<GoodItemDto>) => void,
                     onFailure: (failure: FailureLoading) => void) => {
        post<UpdateBrandRequestDto, ApiResponse<GoodItemDto>>(contextPath() + 'api/v1/good-items/update-good-item/' + brandId, updateBrandRequestDto)
            .then(content => callback(content))
            .catch(error => handleApiError(error, "Unable to update good item", onFailure))
    },

    getAllGoodItems: (brandId: string,
                      callback: (content: ApiResponse<GoodItemDto[]>) => void,
                      onFailure: (failure: FailureLoading) => void) => {
        post<null, ApiResponse<GoodItemDto[]>>(contextPath() + '/api/v1/good-items/get-all-good-items-by-brand/' + brandId, null)
            .then(content => callback(content))
            .catch(error => handleApiError(error, "Unable to get all good items", onFailure))
    },

    getGoodItem: (goodItemId: string,
                  callback: (content: ApiResponse<GoodItemDto>) => void,
                  onFailure: (failure: FailureLoading) => void) => {
        post<null, ApiResponse<GoodItemDto>>(contextPath() + '/api/v1/good-items/get-good-item/' + goodItemId, null)
            .then(content => callback(content))
            .catch(error => handleApiError(error, "Unable to get brand", onFailure))
    },

    deleteGoodItem: (goodItemId: string,
                     callback: (content: ApiResponse<GoodItemDto>) => void,
                     onFailure: (failure: FailureLoading) => void) => {
        post<null, ApiResponse<any>>(contextPath() + '/api/v1/good-items/delete-good-item/' + goodItemId, null)
            .then(content => callback(content))
            .catch(error => handleApiError(error, "Unable to delete good item", onFailure))
    }
}