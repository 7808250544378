import * as React from "react";

interface Props {
    condition: boolean,
    className?: string,
    children: any
}

const If: React.FC<Props> = (props: Props) => {
    return (
        <div className={props.className || ''}>
            {props.condition === true ? props.children : (<></>)}
        </div>
    )
}

export default If;