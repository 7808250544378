import React from 'react';
import {Button, Input} from '@mui/material';
import {SnackbarOrigin, useSnackbar} from "notistack";

export interface FileFromLinkUploadProps {
    key?: string,
    onLoad: (fileLink: string) => void
}

export const FileFromLinkUpload: React.FC<FileFromLinkUploadProps> = (props: FileFromLinkUploadProps) => {
    const [fileLink, setFileLink] = React.useState<string>("");

    const onFileLinkChanged: (event: React.ChangeEvent<HTMLInputElement>) => void = (event) => {
        setFileLink(event.target.value)
    }

    const doUpload = React.useCallback(() => {
        if (fileLink.length == 0) {
            enqueueSnackbar("File URL is not specified", {
                variant: 'error',
                anchorOrigin: snackbarAnchor
            })
        } else {
            props.onLoad(fileLink)
            setFileLink("")
        }
    }, [fileLink]);

    const {enqueueSnackbar} = useSnackbar();
    const snackbarAnchor = React.useMemo<SnackbarOrigin>(() => ({
        horizontal: 'center',
        vertical: 'top'
    }), []);


    return (
        <div>
            <Input value={fileLink} onChange={onFileLinkChanged}/>
            <Button variant="contained" onClick={doUpload}>Upload</Button>
        </div>
    )
}