import {store} from "../store/store";
import {setLoggedUser} from "../store/actions/data";
import {History} from "../history/History";
import axios from 'axios';
import {ApiResponse} from "./ApiResponse";

export async function get<T>(url: string, params: any = {}): Promise<T> {
    const response = await axios.get<T>(url, params);
    return response.data;
}

export async function post<R, T>(url: string, body: R, params: any = {}): Promise<T> {
    const response = await axios.post<T>(url, body, params);
    return response.data;
}

export enum FailureVariant {
    ERROR,
    WARN
}

export type FailureLoading = {
    variant: FailureVariant,
    message: string
}

export type None = {}

export function handleApiError<R>(error: any, message: string, onFailure: (failure: FailureLoading) => void) {
    console.log(message);
    if (error.response && error.response.status && error.response.status === 403) {
        store.dispatch(setLoggedUser({}))
        History.push(contextPath())
        onFailure({
            message: "Unauthorized",
            variant: FailureVariant.ERROR
        } as FailureLoading)
    } else if (error.message) {
        onFailure({
            message: message + ': ' + error.message,
            variant: FailureVariant.ERROR
        })
    } else if (error.response && error.response.data) {
        onFailure({
            message: message + ': ' + (error.response.data as ApiResponse<None>).status.message,
            variant: FailureVariant.ERROR
        } as FailureLoading)
    } else {
        onFailure({
            message: message,
            variant: FailureVariant.ERROR
        })
    }
}

export function contextPath() {
    return window.contextPath;
}