import './App.css'
import React from 'react'
import {Provider} from 'react-redux'
import {PersistGate} from 'redux-persist/integration/react'
import RootComponent from './RootComponent'
import {persistor, store} from './store/store'
import {SnackbarProvider} from "notistack";

const App: React.FC = () => {
    return (
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <SnackbarProvider maxSnack={3} autoHideDuration={5000} preventDuplicate={true}>
                    <RootComponent/>
                </SnackbarProvider>
            </PersistGate>
        </Provider>
    )
}

export default App
