import React from 'react'
import {Container, Grid} from "@mui/material";
import Catalogue from '../components/catalogue/Catalogue'

const HomePage: React.FC = () => {
    return (
        <Container>
            <Grid container={true} spacing={2} rowSpacing={2}>
                <Grid item xs={12} md={12} sm={12}>

                </Grid>
                <Grid item xs={12} md={12} sm={12}>
                    <Catalogue/>
                </Grid>
            </Grid>
        </Container>
    )
}

export default HomePage
