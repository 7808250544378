import ContentPasteGoIcon from '@mui/icons-material/ContentPasteGo';
import { IconButton } from "@mui/material";
import { SnackbarOrigin, useSnackbar } from 'notistack';
import * as React from "react";
import { useAppSelector } from "../../../store/store";
import If from "../../utils/If";
import './Profile.css';

interface Props {
    children?: any
}

const Profile: React.FC<Props> = (props: Props) => {
    const user = useAppSelector((state) => state.data.user)

    const {enqueueSnackbar} = useSnackbar();
    const snackbarAnchor = React.useMemo<SnackbarOrigin>(() => ({
        horizontal: 'center',
        vertical: 'top'
    }), []);

    const copyUrlToClipboard = React.useCallback(() => {
        if (user.abcpSearchUrl !== undefined) {
            navigator.clipboard.writeText(user.abcpSearchUrl).then(function () {
                enqueueSnackbar("Copied to Clipboard", {
                    variant: 'success',
                    anchorOrigin: snackbarAnchor
                })
            }, function (err) {
                enqueueSnackbar("Unable to copy to Clipboard", {
                    variant: 'error',
                    anchorOrigin: snackbarAnchor
                })
            });
        }
    }, [user, snackbarAnchor, enqueueSnackbar]);

    return (
        <div>
            <If condition={user.id !== null && user.id !== undefined && user.id !== ''}>
                <p>
                    User ID: {user.id}
                </p>
                <div>
                    <h3>Как подключиться к ABCP:</h3>
                    <div>
                        1. Создать заявку в техподдержку ABCP с текстом: сделайте интеграцию с внешним сервисом для
                        хранения картинок
                        <br/>
                    </div>
                    <div>
                        2. ВАЖНО: укажите в заявке, что интеграция нужна по варианту "1 товар содержит несколько
                        картинок"
                        <br/>
                    </div>
                    <div>
                        3. Укажите в заявке URL для поиска:<br/>
                        <a href={user.abcpSearchUrl}>{user.abcpSearchUrl}</a>
                        <IconButton onClick={copyUrlToClipboard}>
                            <ContentPasteGoIcon fontSize='medium'/>
                        </IconButton>
                        <br/>
                    </div>
                    <div>
                        4. Укажите в заявке тело для тестового запроса:<br/>
                        <div>
                            <pre className="preCode">
                                {"\n" +
                                    "  [{\"article\": \"test\", \"brand\": \"test\"}]  " +
                                    "\n\n"}
                            </pre>
                        </div>
                        <br/>
                    </div>
                    <hr/>
                </div>
                {props.children}
            </If>
        </div>
    )
}

export default Profile;