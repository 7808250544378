import {AuthApi} from "./auth/AuthApi";
import {UserManagementApi} from "./user/management/UserManagementApi";
import {BrandApi} from "./brand/BrandApi";
import {GoodItemApi} from "./goodItem/GoodItemApi";
import {ImageApi} from "./image/ImageApi";
import {WatermarkApi} from "./watermark/WatermarkApi";
import {AdminApi} from "./admin/AdminApi";
import {BatchFileUploadApi} from "./batch/BatchFileUploadApi";

const Api = {
    auth: AuthApi,
    userManagement: UserManagementApi,
    brand: BrandApi,
    goodItem: GoodItemApi,
    image: ImageApi,
    watermark: WatermarkApi,
    admin: AdminApi,
    batch: BatchFileUploadApi
};

export default Api;